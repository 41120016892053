<template>
  <section class="mb-10">
    <v-card
      class="mt-2 mr-2"
      outlined
    >
      <v-toolbar
        flat
        color="primary"
        dark
        dense
      >
        <v-icon left>
          mdi-clipboard-text-outline
        </v-icon>
        <v-toolbar-title>{{ $t('collect.settings') }}</v-toolbar-title>
        <v-spacer />

        <!-- Restore Settings button -->
        <v-btn
          v-if="$parent.myRole() === 'manager'"
          color="warning"
          fab
          dark
          @click="dialogRestore = true"
        >
          <v-icon>mdi-restore</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="grey--text text--darken-1">
        <v-tabs>
          <v-tab>
            Admin Settings
          </v-tab>
          <v-tab>
            General Settings
          </v-tab>
          <!-- Admin Settings -->
          <v-tab-item>
            <v-list
              dense
            >
              <v-list-item
                v-for="item in $store.state.main.qrCode.details.admin"
                :key="item.label"
                link
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ translateMe(item.label) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-tab-item>
          <!-- General Settings -->
          <v-tab-item>
            <v-list
              dense
            >
              <v-list-group
                v-for="item in $store.state.main.qrCode.details.general"
                :key="item.title"
                v-model="item.active"
                :prepend-icon="item.icon"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="translateMe(item.label)" />
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="child in item.items"
                  :key="child.label"
                  @click="settingsSelected({group: 'general', item: child})"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="translateMe(child.label)" />
                    <v-list-item-subtitle
                      v-if="child.selected !== undefined"
                      v-text="translateMe(child.selected.label)"
                    />
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-list-item
                  v-if="true"
                >
                  <v-list-item-content>
                    <v-checkbox
                      :label="`Checkbox 1:`"
                      hide-details
                    />
                  </v-list-item-content>
                </v-list-item> -->
              </v-list-group>
            </v-list>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>

    <!-- DIALOGS -->
    <v-row justify="center">
      <!-- Settings with radiobutton options -->
      <v-dialog
        v-model="dialogSingle"
        max-width="300"
      >
        <v-card>
          <v-card-title>
            {{ dialogOptions.label }}
          </v-card-title>

          <v-card-text>
            <v-radio-group v-model="radioGroup">
              <v-radio
                v-for="item in dialogOptions.options"
                :key="item.label"
                :label="translateMe(item.label)"
                :value="item.name"
                @click="selectSingleSettings(item)"
              />
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey darken-1"
              text
              @click="dialogSingle = false"
            >
              <div>
                {{ $t('common.cancel') }}
              </div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Settings with checkbox options -->
      <v-dialog
        v-model="dialogCheckbox"
        max-width="300"
      >
        <v-card>
          <v-card-title>
            {{ dialogOptions.label }}
          </v-card-title>

          <v-card-text>
            <v-checkbox
              v-model="checkBox.value"
              :label="translateMe(checkBox.label)"
              hide-details
              @click="selectCheckboxSettings(checkBox)"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="grey darken-1"
              text
              @click="dialogCheckbox = false"
            >
              <div>
                {{ $t('common.cancel') }}
              </div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Settings with checkbox options -->
      <v-dialog
        v-model="dialogRestore"
        max-width="300"
      >
        <v-card>
          <v-card-title>
            {{ $t('common.restore') }}
          </v-card-title>

          <v-card-text>
            {{ $t('collect.restore') }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="grey darken-1"
              text
              @click="dialogRestore = false"
            >
              <div>
                {{ $t('common.cancel') }}
              </div>
            </v-btn>
            <v-spacer />

            <v-btn
              color="grey darken-1"
              text
              @click="restoreQcDetails()"
            >
              <div>
                {{ $t('common.agree') }}
              </div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </section>
</template>

<script>
  // https://docs.getodk.org/collect-import-export/#list-of-keys-for-all-settings
  export default {
    name: 'ODKSettings',

    props: {
      projectname: {
        type: String,
        default: '',
      },
      started: {
        type: String,
        default: '',
      },
      submissions: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        dialogSingle: false,
        dialogCheckbox: false,
        dialogRestore: false,
        dialogOptions: {},
        radioGroup: '',
        checkBox: {},
        SettingGroup: '',
        SettingSubGroup: '',

        qcDetails: {
          project: {
            name: '',
            icon: '',
            color: '',
          },
          // Admin Settings
          admin: [
            { label: 'collect.main-menu-settings', icon: 'mdi-beaker-alert-outline' },
            { label: 'collect.user-settings', icon: 'mdi-account-alert' },
            { label: 'collect.form-entry-settings', icon: 'mdi-clipboard-alert-outline' },
          ],
          // General Settings
          general: [
            // User interface
            {
              label: 'collect.user-interface',
              icon: 'mdi-cellphone-cog',
              active: true,
              items: [
                {
                  label: 'collect.theme',
                  name: 'appTheme',
                  options: [
                    { label: 'collect.light-theme', name: 'light_theme' },
                    { label: 'collect.dark-theme', name: 'dark_theme' },
                  ],
                  selected: { label: 'collect.light-theme', name: 'light_theme' },
                  dialog: 'single',
                },
                // {
                //   label: 'Language',
                //   name: 'app_language',
                //   options: [
                //     // {'af', 'am', 'ar', 'bg', 'bn', 'ca', 'cs', 'da', 'de', 'en', 'es', 'et', 'fa', 'fi', 'fr', 'hi', 'in', 'it', 'ja', 'ka', 'km', 'ln', 'lo_LA', 'lt', 'mg', 'ml', 'mr', 'ms', 'my', 'ne_NP', 'nl', 'no', 'pl', 'ps', 'pt', 'ro', 'ru', 'rw', 'si', 'sl', 'so', 'sq', 'sr', 'sv_SE', 'sw', 'sw_KE', 'te', 'th_TH', 'ti', 'tl', 'tr', 'uk', 'ur', 'ur_PK', 'vi', 'zh', 'zu'}
                //   ],
                //   dialog: 'single',
                // },
                {
                  label: 'collect.navigation',
                  name: 'navigation',
                  options: [
                    { label: 'collect.use-horizontal-swipes', name: 'swipe' },
                    { label: 'collect.use-buttons', name: 'buttons' },
                    { label: 'collect.use-swipes-and-buttons', name: 'swipe_buttons' },
                  ],
                  selected: { label: 'collect.use-horizontal-swipes', name: 'swipe' },
                  dialog: 'single',
                },
              ],
            },
            // Maps
            {
              label: 'collect.maps',
              icon: 'mdi-map',
              items: [
                {
                  label: 'collect.basemap-source',
                  name: 'basemap_source',
                  options: [
                    { label: 'collect.google', name: 'google' },
                    { label: 'collect.mapbox', name: 'mapbox' },
                    { label: 'collect.osm', name: 'osm' },
                    { label: 'collect.usgs', name: 'usgs' },
                    { label: 'collect.stamen', name: 'stamen' },
                    { label: 'collect.carto', name: 'carto' },
                  ],
                  selected: { label: 'collect.google', name: 'google' },
                  dialog: 'single',
                },
              ],
            },
            // Form update
            {
              label: 'collect.form-update',
              icon: 'mdi-clipboard-check-outline',
              items: [
                {
                  label: 'collect.blank-form-update-mode',
                  name: 'form_update_mode',
                  options: [
                    { label: 'collect.manual', name: 'manual' },
                    { label: 'collect.previously-downloaded-forms', name: 'previously_downloaded' },
                    { label: 'collect.exactly-match-server', name: 'match_exactly' },
                  ],
                  selected: { label: 'collect.manual', name: 'manual' },
                  dialog: 'single',
                },
                {
                  label: 'collect.automatic-update-frequency',
                  name: 'periodic_form_updates_check',
                  options: [
                    { label: 'collect.every-fifteen-minutes', name: 'every_fifteen_minutes' },
                    { label: 'collect.every-hour', name: 'every_one_hour' },
                    { label: 'collect.every-six-hours', name: 'every_six_hours' },
                    { label: 'collect.every-day', name: 'every_24_hours' },
                  ],
                  selected: { label: 'collect.every-fifteen-minutes', name: 'every_fifteen_minutes' },
                  dialog: 'single',
                },
                {
                  label: 'collect.automatic-update',
                  name: 'automatic_update',
                  options: [{ label: 'collect.automatic-download', value: false }],
                  selected: { label: 'collect.not-selected', name: 'automatic_update', value: false },
                  forchange: { label: 'collect.selected', name: 'automatic_update', value: true },
                  dialog: 'checkbox',
                },
                {
                  label: 'collect.hide-old-forms',
                  name: 'hide_old_form_versions',
                  options: [{ label: 'collect.hide-old-form-versions', value: false }],
                  selected: { label: 'collect.not-selected', name: 'hide_old_form_versions', value: false },
                  forchange: { label: 'collect.selected', name: 'hide_old_form_versions', value: true },
                  dialog: 'checkbox',
                },
              ],
            },
            // Form submission
            {
              label: 'collect.form-submission',
              icon: 'mdi-clipboard-arrow-up-outline',
              items: [
                {
                  label: 'collect.auto-send',
                  name: 'autosend',
                  options: [
                    { label: 'collect.off', name: 'off' },
                    { label: 'collect.wifi-only', name: 'wifi_only' },
                    { label: 'collect.cellular-only', name: 'cellular_only' },
                    { label: 'collect.wifi-or-cellular', name: 'wifi_and_cellular' },
                  ],
                  selected: { label: 'collect.off', name: 'off' },
                  dialog: 'single',
                },
                {
                  label: 'collect.delete-after-send',
                  name: 'automatic_update',
                  options: [{ label: 'collect.delete-finalized-forms', value: false }],
                  selected: { label: 'collect.not-selected', name: 'automatic_update', value: false },
                  forchange: { label: 'collect.selected', name: 'automatic_update', value: true },
                  dialog: 'checkbox',
                },
              ],
            },
            // Form filling
            {
              label: 'collect.form-filling',
              icon: 'mdi-clipboard-edit-outline',
              items: [
                {
                  label: 'collect.default-to-finalized',
                  name: 'default_completed',
                  options: [{ label: 'collect.mark-form-as-finalized', value: true }],
                  selected: { label: 'collect.selected', name: 'default_completed', value: true },
                  forchange: { label: 'collect.not-selected', name: 'default_completed', value: false },
                  dialog: 'checkbox',
                },
                {
                  label: 'collect.constraint-processing',
                  name: 'constraint_behavior',
                  options: [
                    { label: 'collect.validate-upon-forward-swipe', name: 'on_swipe' },
                    { label: 'collect.defer-validation-until-finalized', name: 'on_finalize' },
                  ],
                  selected: { label: 'collect.validate-upon-forward-swipe', name: 'on_swipe' },
                  dialog: 'single',
                },
                {
                  label: 'collect.high-resolution-video',
                  name: 'high_resolution',
                  options: [{ label: 'collect.enable-high-resolution-video', value: false }],
                  selected: { label: 'collect.not-selected', name: 'high_resolution', value: false },
                  forchange: { label: 'collect.selected', name: 'high_resolution', value: true },
                  dialog: 'checkbox',
                },
                {
                  label: 'collect.image-size',
                  name: 'image_size',
                  options: [
                    { label: 'collect.original-size-from-camera', name: 'original' },
                    { label: 'collect.very-small-image', name: 'very_small' },
                    { label: 'collect.small-image', name: 'small' },
                    { label: 'collect.medium-image', name: 'medium' },
                    { label: 'collect.large-image', name: 'large' },
                  ],
                  selected: { label: 'collect.original-size-from-camera', name: 'original' },
                  dialog: 'single',
                },
                {
                  label: 'collect.show-guidance-for-questions',
                  name: 'guidance_hint',
                  options: [
                    { label: 'collect.no', name: 'no' },
                    { label: 'collect.yes', name: 'yes' },
                    { label: 'collect.yes-collapsed', name: 'yes_collapsed' },
                  ],
                  selected: { label: 'collect.no', name: 'no' },
                  dialog: 'single',
                },
                {
                  label: 'collect.external-app-recording',
                  name: 'external_app_recording',
                  options: [{ label: 'collect.use-external-for-audio', value: false }],
                  selected: { label: 'collect.not-selected', name: 'external_app_recording', value: false },
                  forchange: { label: 'collect.selected', name: 'external_app_recording', value: true },
                  dialog: 'checkbox',
                },
              ],
            },
          ],
        },

        // Original Settings of ODK Collect
        qcOriginal: {
          project: {
            name: '',
            icon: '',
            color: '',
          },
          admin: {
            admin_pw: '',
            edit_saved: true,
            send_finalized: true,
            view_sent: true,
            get_blank: true,
            delete_saved: true,
            qr_code_scanner: true,
            change_server: true,
            change_app_theme: true,
            change_app_language: true,
            change_font_size: true,
            change_navigation: true,
            show_splash_screen: true,
            maps: true,
            periodic_form_updates_check: true,
            automatic_update: true,
            hide_old_form_versions: true,
            change_autosend: true,
            delete_after_send: true,
            default_to_finalized: true,
            change_constraint_behavior: true,
            high_resolution: true,
            image_size: true,
            guidance_hint: true,
            external_app_recording: true,
            instance_form_sync: true,
            change_form_metadata: true,
            analytics: true,
            moving_backwards: true,
            access_settings: true,
            change_language: true,
            jump_to: true,
            save_mid: true,
            save_as: true,
            mark_as_finalized: true,
          },
          general: {
            protocol: 'odk_default',
            server_url: '',
            username: '',
            password: '',
            formlist_url: '',
            submission_url: '',
            selected_google_account: '',
            google_sheets_url: '',
            appTheme: 'light_theme',
            app_language: '',
            font_size: 13,
            navigation: 'swipe',
            showSplash: true,
            splashPath: '',
            basemap_source: 'google',
            google_map_style: '1',
            mapbox_map_style: 'mapbox://styles/mapbox/light-v10',
            usgs_map_style: 'topographic',
            carto_map_style: 'positron',
            reference_layer: '',
            form_update_mode: 'manual',
            periodic_form_updates_check: 'every_fifteen_minutes',
            automatic_update: true,
            hide_old_form_versions: true,
            autosend: 'off',
            delete_send: true,
            default_completed: true,
            constraint_behavior: 'on_swipe',
            high_resolution: true,
            image_size: 'original',
            external_app_recording: true,
            guidance_hint: 'no',
            instance_sync: true,
            analytics: true,
            metadata_username: '',
            metadata_phonenumber: '',
            metadata_email: '',
          },
        },

        // Using as reference
        qcRefSettings: {
          project: {
            name: '',
            icon: '',
            color: '',
          },
          admin: {
            admin_pw: '',

            // User access control to the main menu. The default value is true.
            edit_saved: true,
            send_finalized: true,
            view_sent: true,
            get_blank: true,
            delete_saved: true,
            qr_code_scanner: true,

            change_server: true,
            change_app_theme: true,
            change_app_language: true,
            change_font_size: true,
            change_navigation: true,
            show_splash_screen: true,
            maps: true,
            periodic_form_updates_check: true,
            automatic_update: true,
            hide_old_form_versions: true,
            change_autosend: true,
            delete_after_send: true,
            default_to_finalized: true,
            change_constraint_behavior: true,
            high_resolution: true,
            image_size: true,
            guidance_hint: true,
            external_app_recording: true,

            instance_form_sync: true,
            change_form_metadata: true,
            analytics: true,

            moving_backwards: true,
            access_settings: true,
            change_language: true,
            jump_to: true,
            save_mid: true,
            save_as: true,
            mark_as_finalized: true,
          },
          general: {
            // Server
            protocol: 'odk_default', // 'google_sheets'
            server_url: '',
            username: '',
            password: '',
            formlist_url: '',
            submission_url: '',
            selected_google_account: '',
            google_sheets_url: '',

            // User interface
            appTheme: 'light_theme', // 'dark_theme'
            app_language: '',
            font_size: 13,
            navigation: 'swipe', // 'buttons', 'swipe_buttons'
            showSplash: true,
            splashPath: '', // Absolute path to splash image

            // Maps
            basemap_source: 'google', // 'mapbox', 'osm', 'usgs', 'stamen', 'carto'
            google_map_style: '1', // 2, 3, 4
            mapbox_map_style: 'mapbox://styles/mapbox/light-v10', // 'mapbox://styles/mapbox/dark-v10', 'mapbox://styles/mapbox/satellite-v9', 'mapbox://styles/mapbox/satellite-streets-v11', 'mapbox://styles/mapbox/outdoors-v11'
            usgs_map_style: 'topographic', // 'hybrid', 'satellite'
            carto_map_style: 'positron', // 'dark_matter'
            reference_layer: '', // Absolute path to mbtiles file

            // Form management
            form_update_mode: 'manual', // 'previously_downloaded', 'match_exactly'
            periodic_form_updates_check: 'every_fifteen_minutes', // 'every_one_hour' ,  'every_six_hours' ,  'every_24_hours'
            automatic_update: true,
            hide_old_form_versions: true,
            autosend: 'off', // 'wifi_only' ,  'cellular_only' ,  'wifi_and_cellular'
            delete_send: true,
            default_completed: true,
            constraint_behavior: 'on_swipe', // 'on_finalize'
            high_resolution: true,
            image_size: 'original', // 'small' ,  'very_small' ,  'medium' ,  'large'
            external_app_recording: true,
            guidance_hint: 'no', // 'yes' ,  'yes_collapsed'
            instance_sync: true,

            // User and device identity
            analytics: true,
            metadata_username: '',
            metadata_phonenumber: '',
            metadata_email: '',
          },
        },

        // Not using project: {} section as it is generated in ocpu and not changing
        qcSettings: {
          admin: {},
          general: {},
        },
      }
    },

    mounted () {
      const defDetails = JSON.parse(JSON.stringify(this.qcDetails))
      this.$store.commit('main/setQcDetails', defDetails)

      // Commit original QR Code settings
      this.$store.commit('main/setQcOriginal', this.qcOriginal)

      // Get QR Code Settings from ocpu (run parent's function)
      this.$parent.getQcDetails()
    },

    // Saves settings into main store and sends to ocpu

    methods: {
      translateMe (val) {
        return this.$t(val)
      },

      // ODK Collect settings selected
      settingsSelected (d) {
        // console.log(d)
        // console.log(d.item.name)
        // console.log(d.item.selected.name)
        this.SettingGroup = d.group
        this.SettingSubGroup = d.item.name
        this.dialogOptions = d.item
        if (d.item.dialog === 'single') {
          this.radioGroup = d.item.selected.name
          this.dialogSingle = true
        } else if (d.item.dialog === 'checkbox') {
          this.checkBox = d.item.options[0]
          this.dialogCheckbox = true
        }
      },

      selectSingleSettings (item) {
        // console.log(item)
        const prop = {
          type: 'single',
          group: this.SettingGroup,
          subgroup: this.SettingSubGroup,
          value: item.name,
        }

        this.$parent.prepQcDetails(prop)
        this.dialogSingle = false
      },

      selectCheckboxSettings (item) {
        // console.log(item)
        const prop = {
          type: 'checkbox',
          group: this.SettingGroup,
          subgroup: this.SettingSubGroup,
          value: item.value,
        }

        this.$parent.prepQcDetails(prop)
        this.dialogCheckbox = false
      },

      // Restore ODK Collect settings to defaults.
      async restoreQcDetails () {
        // Restore qrCode.updated object
        const defDetails = JSON.parse(JSON.stringify(this.qcDetails))
        this.$store.commit('main/setQcDetails', defDetails)

        // restore qrCode.updated object
        const qcProject = this.$store.state.main.qrCode.updated.project
        const cleanSettings = {
          project: qcProject,
          admin: {},
          general: {},
        }
        this.$store.commit('main/setQcUpdated', cleanSettings)

        // Update qrCode.updated in ocpu
        this.$parent.saveQcDetails(JSON.stringify(cleanSettings)).then(
          response => {
            // console.log(response)
            this.dialogRestore = false
          },
          error => {
            return (error)
            // console.log('Cannot restore)
          },
        )
      },
    },
  }
</script>
